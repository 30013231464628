.loader-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0 0 0 / 17%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.loader {
    border-top: 5px solid;
    border-color: #292f7b;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
}
.loader-text {
    color: #292f7b;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
