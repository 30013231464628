.table-header {
    background: linear-gradient(360deg, #2c58a2, #292f7b);
    color: white;
    box-shadow: 0px 4px 8px #2c58a2;
    position: relative;
}

.table-border {
    border: 1px solid #e8e8e8;
}

.table-footer {
    background: linear-gradient(180deg, #2c58a2, #292f7b);
    color: white;
    box-shadow: 0px -4px 8px #2c58a2;
    position: relative;
}

.pagination-goto {
    max-width: 60px;
}

.pagination-btn {
    width: 45px;
}

.no-data {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
}

.table-row {
    height: 50px;
}

#dropdown-basic::after {
    display: none !important;
}
